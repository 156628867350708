<template>
  <v-container fluid>
    <v-card>
      <v-card-title
        :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'card-header']"
      >
        <div
          class="card-header-title font-size-lg text-capitalize font-weight-normal"
        >
          {{ $t("analytics.lang_itemSalesTime") }}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn">
          <b-dropdown
            no-caret
            right
            toggle-class="btn-icon btn-icon-only"
            :menu-class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
            variant="link"
          >
            <span slot="button-content">
              <i class="pe-7s-menu btn-icon-wrapper"> </i>
            </span>
            <div>
              <h6 class="dropdown-header" tabindex="-1">
                {{ $t("generic.lang_Options") }}
              </h6>
              <button
                :class="[
                  this.$vuetify.theme.dark ? 'white--text' : '',
                  'dropdown-item',
                ]"
                tabindex="0"
                type="button"
              >
                <v-icon class="dropdown-icon" color="warning">
                  picture_as_pdf
                </v-icon>
                <span class="text-capitalize">
                  {{ $t("generic.lang_export") }} PDF
                </span>
              </button>

              <button
                :class="[
                  this.$vuetify.theme.dark ? 'white--text' : '',
                  'dropdown-item',
                ]"
                tabindex="0"
                type="button"
              >
                <font-awesome-icon
                  class="dropdown-icon success--text"
                  icon="file-excel"
                />
                <span>Excel</span>
              </button>
            </div>
          </b-dropdown>
        </div>
      </v-card-title>

      <v-card-text class="mt-6">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="itemID"
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              item-text="name"
              item-value="id"
              :label="$t('erp.lang_items')"
              clearable
              outlined
              dense
              flat
              cache-items
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              dense
              item-text="name"
              item-value="id"
              outlined
              :items="dateOptions"
              v-model="dateOption"
            />
          </v-col>

          <v-col cols="12" sm="6" v-if="dateOption == 6">
            <BaseDateInput
              dense
              :label="$t('generic.lang_from')"
              outlined
              type="date"
              :rules="[(v) => !!v && validFromToDate]"
              v-model="startDate"
            />
          </v-col>

          <v-col cols="12" sm="6" v-if="dateOption == 6">
            <BaseDateInput
              dense
              :label="$t('generic.lang_till')"
              outlined
              type="date"
              :rules="[(v) => !!v && validFromToDate]"
              v-model="endDate"
            />
          </v-col>

          <v-col class="mt-0 pt-0" cols="12">
            <v-btn
              :disabled="
                loading ||
                (dateOption == 6 &&
                  (!startDate || !endDate || !validFromToDate))
              "
              :loading="loading"
              @click="getSalesData"
              block
              class="bg-primary text-light mx-auto"
            >
              <v-icon class="ma-1">remove_red_eye</v-icon>
              {{ $t("generic.lang_display") }}
            </v-btn>
          </v-col>

          <v-col class="pa-0 ma-0" cols="12">
            <v-divider class="pa-0 ma-0" />
          </v-col>

          <v-col cols="12" class="mx-auto" v-if="showTopChart">
            <v-card>
              <v-card-text class="pa-0">
                <div v-if="loading" class="text-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>

                <apexchart
                  v-else
                  height="600"
                  type="bar"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" v-if="showTable">
            <v-card>
              <v-card-text class="pa-0">
                <v-data-table
                  v-if="dateOptionType === 'day'"
                  :items="datatableItems"
                  :headers="oneDayDatatableHeaders"
                >
                </v-data-table>

                <v-data-table
                  v-if="dateOptionType === 'days'"
                  :items="datatableItems"
                  :headers="daysDataTableHeaders"
                >
                  <template v-slot:item.price="{ item }">
                    {{ item.price | currency }}
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{ item.total | currency }}
                  </template>
                </v-data-table>

                <v-data-table
                  v-if="dateOptionType === 'months'"
                  :items="datatableItems"
                  :headers="monthsDataTableHeaders"
                >
                  <template v-slot:item.price="{ item }">
                    {{ item.price | currency }}
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{ item.total | currency }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import moment from "moment";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueApexCharts from "vue-apexcharts";
import BaseDateInput from "@/components/common/BaseDateInput.vue";

library.add(faFileExcel);

export default {
  name: "ItemSalesTimeComponent",

  components: {
    apexchart: VueApexCharts,
    "font-awesome-icon": FontAwesomeIcon,
    BaseDateInput,
  },

  data() {
    return {
      series: [
        {
          name: this.$t("generic.lang_itemsale"),
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          stacked: true,
          type: "bar",
          width: "100%",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        plotOptions: {
          bar: {
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        labels: [],
        xaxis: {
          title: {
            text:
              this.$t("generic.lang_time") +
              ` (${this.$t("generic.lang_hour")})`,
          },
        },
        yaxis: [
          {
            seriesName: "itemsale",
            // labels: {
            //   formatter: (y) => this.$options.filters.currency(y),
            // },
            title: {
              text: this.$t("generic.lang_totalInEuro"),
            },
          },
        ],
      },

      loading: false,
      showTopChart: false,
      itemID: null,
      dateOption: 1,
      startDate: null,
      endDate: null,

      isLoading: false,
      items: [],
      search: null,
      awaitingSearch: null,

      showTable: false,
      datatableItems: [],

      dateOptionType: null,
    };
  },
  computed: {
    oneDayDatatableHeaders() {
      return [
        {
          text: this.$t("00"),
          value: "00",
        },
        {
          text: this.$t("01"),
          value: "01",
        },
        {
          text: this.$t("02"),
          value: "02",
        },
        {
          text: this.$t("03"),
          value: "03",
        },
        {
          text: this.$t("04"),
          value: "04",
        },
        {
          text: this.$t("05"),
          value: "05",
        },
        {
          text: this.$t("06"),
          value: "06",
        },
        {
          text: this.$t("07"),
          value: "07",
        },
        {
          text: this.$t("08"),
          value: "08",
        },
        {
          text: this.$t("09"),
          value: "09",
        },
        {
          text: this.$t("10"),
          value: "10",
        },
        {
          text: this.$t("11"),
          value: "11",
        },
        {
          text: this.$t("12"),
          value: "12",
        },
        {
          text: this.$t("13"),
          value: "13",
        },
        {
          text: this.$t("14"),
          value: "14",
        },
        {
          text: this.$t("15"),
          value: "15",
        },
        {
          text: this.$t("16"),
          value: "16",
        },
        {
          text: this.$t("17"),
          value: "17",
        },
        {
          text: this.$t("18"),
          value: "18",
        },
        {
          text: this.$t("19"),
          value: "19",
        },
        {
          text: this.$t("20"),
          value: "20",
        },
        {
          text: this.$t("21"),
          value: "21",
        },
        {
          text: this.$t("22"),
          value: "22",
        },
        {
          text: this.$t("23"),
          value: "23",
        },
      ];
    },
    daysDataTableHeaders() {
      return [
        {
          text: this.$t("generic.lang_date"),
          value: "date",
        },
        {
          text: this.$t("generic.lang_soldQuantity"),
          value: "qty",
        },
        {
          text: this.$t("analytics.lang_itemSellPrice"),
          value: "price",
        },
        {
          text: this.$t("analytics.lang_totalSellPrice"),
          value: "total",
        },
      ];
    },
    monthsDataTableHeaders() {
      return [
        {
          text: this.$t("generic.lang_month"),
          value: "month",
        },
        {
          text: this.$t("generic.lang_soldQuantity"),
          value: "qty",
        },
        {
          text: this.$t("analytics.lang_itemSellPrice"),
          value: "price",
        },
        {
          text: this.$t("analytics.lang_totalSellPrice"),
          value: "total",
        },
      ];
    },
    dateOptions() {
      return [
        {
          id: 1,
          name: this.$t("generic.lang_today"),
        },
        {
          id: 2,
          name: this.$t("generic.lang_thisWeek"),
        },
        {
          id: 3,
          name: this.$t("generic.lang_last_week"),
        },
        {
          id: 4,
          name: this.$t("generic.lang_this_month"),
        },
        {
          id: 5,
          name: this.$t("generic.lang_last_month"),
        },
        {
          id: 6,
          name: this.$t("generic.lang_from_to"),
        },
      ];
    },
    validFromToDate() {
      if (this.startDate && this.endDate) {
        return this.getFrom() <= this.getTo();
      } else return true;
    },
  },
  watch: {
    search(val) {
      // Items have already been loaded
      // if (this.items.length > 0) return;

      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if ((val && val.length === 0) || !!val == false) {
          return;
        }

        this.isLoading = true;

        this.axios
          .post(ENDPOINTS.ERP.ITEM.SEARCH, {
            query: val,
          })
          .then((res) => {
            console.log(res.data);

            if (res.data.status === "SUCCESS") {
              this.items = res.data.data.foundItems;
            }
          })
          .catch(() => {})
          .finally(() => {
            this.isLoading = false;
          });
      }, 500);
    },
  },
  methods: {
    getFrom() {
      let startDate = new Date(this.startDate);
      return moment(startDate).startOf("day").unix();
    },
    getTo() {
      let endDate = new Date(this.endDate);
      return moment(endDate).endOf("day").unix();
    },
    getThisWeek() {
      let current = Date.now();
      return moment(current).startOf("weeks").unix();
    },
    getLastWeek() {
      let current = Date.now();
      return moment(current).startOf("weeks").subtract(1, "weeks");
    },
    getThisMonth() {
      let current = Date.now();
      return moment(current).startOf("months").unix();
    },
    getLastMonth() {
      let current = Date.now();
      return moment(current).startOf("months").subtract(1, "months");
    },
    getForm() {
      let form = {};
      let now = Date.now();

      if (this.dateOption == 1)
        form = {
          startDate: moment(now).startOf("day").unix(),
          endDate: moment(now).endOf("day").unix(),
        };
      else if (this.dateOption == 2)
        form = {
          startDate: this.getThisWeek(),
          endDate: moment(now).unix(),
        };
      else if (this.dateOption == 3)
        form = {
          startDate: this.getLastWeek().unix(),
          endDate: moment(this.getLastWeek()).endOf("weeks").unix(),
        };
      else if (this.dateOption == 4)
        form = {
          startDate: this.getThisMonth(),
          endDate: moment(now).unix(),
        };
      else if (this.dateOption == 5) {
        form = {
          startDate: this.getLastMonth().unix(),
          endDate: moment(this.getLastMonth()).endOf("months").unix(),
        };
      } else if (this.dateOption == 6) {
        form = {
          startDate: this.getFrom(),
          endDate: this.getTo(),
        };
      }

      form.itemId = this.itemID;

      return form;
    },
    getSalesData() {
      let self = this;
      this.loading = true;
      this.showTopChart = false;
      this.showTable = false;
      this.dateOptionType = null;

      this.axios
        .post(
          "get/analytics/wareanalytics/individualItemSales/",
          this.getForm()
        )
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.length === 0) {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_noDataToDisplay"),
                color: "warning",
              });
              return;
            }

            if (Object.hasOwn(res.data.data[0], "hour")) {
              self.chartOptions.labels = [];
              self.series[0].data = [];
              self.datatableItems = [{}];
              self.chartOptions.xaxis.title.text =
                this.$t("generic.lang_time") +
                ` (${this.$t("generic.lang_hour")})`;

              for (let hour = 0; hour < 24; hour++) {
                const h = hour < 10 ? `0${hour}` : hour;
                self.chartOptions.labels.push(h + ":00");
                self.series[0].data[hour] = 0;
                self.datatableItems[0][h] = 0;
              }

              res.data.data.forEach((item) => {
                self.series[0].data[item.hour] = parseInt(item.qty);
                self.datatableItems[0][item.hour] =
                  parseFloat(item.totalPricePerItem) * parseInt(item.qty);
              });

              self.showTopChart = true;
              self.showTable = true;
              self.dateOptionType = "day";
            } else if (Object.hasOwn(res.data.data[0], "day")) {
              self.chartOptions.labels = [];
              self.series[0].data = [];
              self.datatableItems = [];
              self.chartOptions.xaxis.title.text = this.$t("generic.lang_days");

              res.data.data.forEach((item, i) => {
                self.chartOptions.labels.push(item.day);
                self.series[0].data[i] = parseInt(item.qty);
                self.datatableItems.push({
                  date: item.day,
                  qty: parseInt(item.qty),
                  price: item.totalPricePerItem,
                  total:
                    parseFloat(item.totalPricePerItem) * parseInt(item.qty),
                });
              });

              self.showTopChart = true;
              self.showTable = true;
              self.dateOptionType = "days";
            } else if (Object.hasOwn(res.data.data[0], "month")) {
              self.chartOptions.labels = [];
              self.series[0].data = [];
              self.datatableItems = [];
              self.chartOptions.xaxis.title.text = this.$t(
                "generic.lang_months"
              );

              res.data.data.forEach((item, i) => {
                self.chartOptions.labels.push(item.month);
                self.series[0].data[i] = parseInt(item.qty);
                self.datatableItems.push({
                  month: item.month,
                  qty: parseInt(item.qty),
                  price: item.totalPricePerItem,
                  total:
                    parseFloat(item.totalPricePerItem) * parseInt(item.qty),
                });
              });

              self.showTopChart = true;
              self.showTable = true;
              self.dateOptionType = "months";
            }

            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_dataLoaded"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.msg || this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
